/* src/styles/components/MenuBar.css */

.menu-bar-items .p-menuitem {
    margin-right: 1.5rem; 
    font-weight: 700;
    font-size: 0.8rem;/* Zwiększ odstęp między elementami menu */
  }
  
  .menu-bar-items .p-menuitem:last-child {
    margin-right: 0; /* Usuń margines dla ostatniego elementu */
  }
  
  .menu-bar-items .p-menuitem-link {
    padding: 0.5rem 1rem; /* Dodaj padding, aby zwiększyć kliknięcie */
  }
  
  .menu-bar-items .p-menuitem-link:hover {
    background-color: #374151; /* Kolor tła dla efektu hover */
  }

  .icon-transition {
    transition: transform 0.5s ease; /* Dodaje płynne przejście dla transformacji */
  }
  
  .icon-moon {
    transform: rotate(0deg); /* Początkowa transformacja dla ikony księżyca */
  }
  
  .icon-sun {
    transform: rotate(180deg);
  }

  .custom-switch .p-inputswitch-slider {
    transition: background-color 0.2s, border-color 0.2s;
  }
  
  .custom-switch .p-inputswitch-slider:before {
    transition: 0.2s;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  
.menu-content {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-bar {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
  /* Add a new class for the non-sticky state */
  .menu-bar.not-sticky {
    transform: translateY(-100%);
  }

  /* Modify the existing .menu-bar.sticky class */
  .menu-bar.sticky {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  /* ... rest of the existing styles remain the same */  

.menu-bar-items .p-menuitem-link {
  color: inherit !important;
}

.menu-bar-items .p-menuitem-text {
  color: inherit !important;
}
.menu-bar-items-custom .p-menubar-root .p-menuitem-link {
  color: #1E1E1E !important; /* Ciemny kolor tekstu */
}

.dark-mode .menu-bar-items-custom .p-menubar-root .p-menuitem-link {
  color: white !important; /* Biały tekst w trybie ciemnym */
}


.menu-icon {
  width: 35px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menu-icon:hover {
  transform: scale(1.1);
}

.menu-icon .bar1,
.menu-icon .bar2,
.menu-icon .bar3 {
  width: 100%;
  height: 4px;
  background-color: #000;
  transition: all 0.3s ease;
}

.menu-icon.open .bar1 {
  transform: rotate(45deg) translate(9px, 9px);
  background-color: #ED1C24;
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(-45deg) translate(9px, -9px);
  background-color: #ED1C24;
}


/* Light Mode */
.light-mode .menu-icon .bar1,
.light-mode .menu-icon .bar2,
.light-mode .menu-icon .bar3 {
  background-color: #000;
}

.light-mode .menu-icon.open .bar1,
.light-mode .menu-icon.open .bar3 {
  background-color: #ED1C24;
}

/* Dark Mode */
.dark-mode .menu-icon .bar1,
.dark-mode .menu-icon .bar2,
.dark-mode .menu-icon .bar3 {
  background-color: #fff;
}

.dark-mode .menu-icon.open .bar1,
.dark-mode .menu-icon.open .bar3 {
  background-color: #fff;
}
