.parentFooter {
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr 1fr 1fr; /* Zmieniono trzecią wartość z 1fr na 0.5fr */
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap:0px;
    grid-row-gap: 0px;
    background-color: #1E1E1E;
    color: white;
    padding: 2rem;
  }
  
  .div1footer { grid-area: 1 / 1 / 2 / 3; }
  .div2footer { grid-area: 1 / 4 / 2 / 5; }
  .div3footer { grid-area: 1 / 5 / 2 / 6; }
  .div4footer { grid-area: 2 / 1 / 3 / 3; }
  .div5footer { grid-area: 2 / 4 / 3 / 5; }
  .div6footer { grid-area: 2 / 5 / 3 / 6; }
  .div7footer { grid-area: 3 / 4 / 4 / 6; }
  
  .div1footer img {
    height: 8rem;
  }
  
  .div2footer, .div3footer, .div5footer, .div6footer {
    display: flex;
    flex-direction: column;
  }
  
  .div4footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .div7footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  
  
  .div7footer a {
    height: auto;
    width: 40%; /* Każda ikona zajmuje około 22% szerokości diva, zostawiając trochę miejsca między nimi */
  }
  
  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin: 0.25rem 0;
  }
  .gradient-sectionFooter {
    width: 100%;
    background: linear-gradient(to top, #ED1C24 2%, #1E1E1E 70%, transparent);
    min-height: 200px;
  }
  
  .gradient-sectionFooter > * {
    position: absolute;
    z-index: 1;
  }
  