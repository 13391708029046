.under-construction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 1rem;
  padding-bottom: 60px; /* Add padding to accommodate the fixed button */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.construction-animation {
  position: relative;
  width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gear {
  position: relative;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/images/underConstruction/gear-icon.svg');
}

h1 {
  margin-top: 1rem;
  font-size: 2rem;
  color: #333;
}

.go-back-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.1rem;
  background-color: #ED1C24;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  z-index: 100;
}

.newsletter-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-section h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.newsletter-card {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
}

@media (min-width: 768px) {
  .newsletter-card {
    width: 70%;
  }
}

.newsletter-card form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.newsletter-card .MuiFormControlLabel-root {
  margin-top: 1rem;
}

.newsletter-card .MuiButton-root {
  align-self: center;
  margin-top: 1rem;
}
